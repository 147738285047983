import * as React from "react";

export interface IProps {
  bg?: string;
  border?: string;
  simbol?: string;
  intro?: string;
  color?: string;
}

const ServiceItem: React.FC<IProps> = (prop: IProps) => {
  // const [departure, setDeparture] = React.useState<Date | null>(null);

  return (
    <div className="services_home_item" style={{ backgroundColor: prop.bg || 'transparent', border: `3px solid ${ prop.border || 'none' }`  }}>
      <span className="box white aos-init aos-animate">
        <i className="letr">{ prop.intro ? prop.intro.substr(0,1) : 'A' }</i>
        <p className="text" style={{ color: prop.color || '#638db9' }}>{ prop.intro ? prop.intro : '...' }</p>
      </span>
    </div>
  );
};

export default ServiceItem;
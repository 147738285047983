import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import Slider from '../components/Slider';
import ServicesItem from '../components/Services_item';
import '../styles/App.scss';
import services_svg from '../assets/services.svg';
import about_img_webp from '../assets/about-img.webp';
import anim_webm from '../assets/main_animate.webm';
import anim_mp4 from '../assets/main_animate.mp4';

const Index = () => {
  return (
    <Layout>
      <Helmet>
        <title>Создание сайтов в Тирасполе</title>
      </Helmet>
      <section className="main container d-flex flex-column flex-sm-row justify-content-around py-4 py-sm-5 my-0 my-sm-5">
        <video autoPlay={true} loop={true} muted={true} playsInline className="main_img animated fadeIn">  
          <source src={anim_webm} type="video/webm"/>  
          <source src={anim_mp4} type="video/mp4"/>  
        </video>  
        <Slider/>
      </section>
      <section className="about_us_home container d-flex flex-column flex-sm-row justify-content-around">
        <div className="container order-2 order-sm-1">
          <div className="row">
            <div className="col-xs-12 col-xl-6">
              <h1 className="title animated fadeInUp slow text-center text-sm-left">We Are</h1>
              <p className="content animated fadeInUp slow" style={{width: '630px'}}>
              Веб-студия «ArtCode» работает с интернет-магазинами, b2b решениями,
               туристическими компаниями, финансовыми учреждениями и другими бизнес-структурами.
               Помогает оптимизировать, масштабировать и улучшать свои бизнес-процессы за счет эффективных
               внедрений множества интеллектуальных технологий. Благодаря инновационному
               и автоматизированному подходу, мы сглаживаем рабочий процесс, чтобы ускорить
               рост организации за счет использования минимальных ресурсов.
               Мы идем в ногу со временем, поэтому используем самые свежие и проверенные разработки
               инструменты для создания и раскрутке сайтов, мобильных приложений, сервисов, рекламы.
              </p>
              <Link to="/about" className="link animated fadeInUp slow">О нас</Link>
            </div>
            <div className="about_us_home_img order-1 order-sm-2">
              <img src={about_img_webp} alt="popple office" className="response_img animated bounceInUp"/>
            </div>
          </div>
        </div>
      </section>
      <section className="services_home d-flex flex-column flex-sm-row justify-content-around">
        <div className="service_shape"><img src={services_svg} alt=""/></div>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-6 d-flex justify-content-around ">
              <div className="boxes_wrap d-flex flex-column">
                <ServicesItem intro="Web app" bg="#ffffff"/>
                <ServicesItem intro="E-commerce" color="white" bg="#fdb811"/>
                <ServicesItem intro="Landing Page" color="white" bg="rgb(36, 89, 145)"/>
              </div>
              <div className="boxes_wrap d-flex flex-column pt-5">
                <span className="shape mb-5"></span>
                <ServicesItem intro="Services" bg="#003a78"/>
                <ServicesItem intro="Mobile app" border="#638db9"/>
              </div>
              <div className="boxes_wrap d-flex flex-column pt-5">
                <ServicesItem intro="Correspondence Management" bg="#ffffff"/>
              </div>
            </div>
            <div className="col-xs-12 col-sm-5 offset-0 offset-sm-1 align-self-center">
              <h1 className="title animated fadeInUp slow">Услуги</h1>
              <p className="content animated fadeInUp slow" style={{width: '470px'}}>
                Обладая обширными знаниями в области управления процессами, наша команда может
                 обогатить общую компетенцию вашей организации.
                 Узнайте больше об услугах, которые мы предлагаем.
              </p>
              <Link to="/service" className="link animated fadeInUp slow">Посмотреть услуги</Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Index;


// import React from 'react';
// import './styles/App.scss';
// import MRouter from './router'
// import Header from './components/Header';
// import Footer from './components/Footer';
// import { BrowserRouter as Router } from 'react-router-dom'
// import { useLocation } from "react-router-dom";
// import { Provider } from 'react-redux';
// import { store } from './store';

// export const ScrollToTop = () => {
//   const { pathname } = useLocation();
//   React.useEffect(() => {window.scrollTo(0, 0)}, [pathname] );
//   return null;
// }

// export const App = () => {
//   return (
//     <Provider store={store}>
//       <Router>
//         <ScrollToTop />
//           <div className="App">
//             <Header />
//             <MRouter/>
//             <Footer/>
//           </div>
//       </Router>
//     </Provider>
//   );
// }

// export default App;
import * as React from "react";
import { Link } from "gatsby";
// import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

export interface IProps {
  title?: string;
  thumb_url?: string;
  link?: string;
  text_link?: string,
  description?: string;
}

const data: Array<IProps> =  [
  {
    title: 'Single Page \n Application -\n most fastest \n web apps',
    thumb_url: 'https://assets-global.website-files.com/5b4cba745747fc721ba46069/5b56260371ac8cec93a9c62c_b2b-ecommerce-definition.jpg',
    link: '/service',
    text_link: 'More about SPA',
    description: ''
  },
  {
    title: 'WordPress - \n most popular \n CMS for \n websites create',
    thumb_url: 'https://assets-global.website-files.com/5b4cba745747fc721ba46069/5b56260371ac8cec93a9c62c_b2b-ecommerce-definition.jpg',
    link: '/service',
    text_link: 'More about WordPress',
    description: ''
  },
  {
    title: 'ReactNative -\n cross platform \n mobile apps',
    thumb_url: 'https://assets-global.website-files.com/5b4cba745747fc721ba46069/5b56260371ac8cec93a9c62c_b2b-ecommerce-definition.jpg',
    link: '/service',
    text_link: 'More about ReactNative',
    description: ''
  }
]

const Slider: React.FC<IProps> = () => {
  const [currentSlide, setCurrentSlide] = React.useState<number>(0);
  const [isActive, setisActive] = React.useState<boolean>(true);

  React.useEffect(() => {
    let interval = setInterval(() => {
      setCurrentSlide(() => currentSlide >= data.length-1 ? 0 : currentSlide + 1);
    }, 10000); // } else if (!isActive && seconds !== 0) { clearInterval(interval); }
    return () => clearInterval(interval);
  });

  const addEffectTitle = (item : string) => {
    return item !== undefined 
      ? item.split('\n').map((d,i)=> <div key={i} className="d-block overflow-hidden"><p>{d}</p></div>) 
      : ''
  }

  return (
    <div className="slider text-center text-sm-left col-md-4 d-flex">
      <div className="slider_wrap mt-4 mt-sm-0 d-flex align-items-start align-items-sm-center">
      {data.map((item,i) => {
        return (
          <div key={i} className={`slider_item ${currentSlide === i ? 'active_slider' : ''}`}>
            <div className="wrap_text mb-4">{addEffectTitle(`${item.title}`)}</div>
            <div className="d-block overflow-hidden pb-3">
              <Link to={item.link || '/'} className="d-block overflow-hidden pb-3 link">
                {item.text_link}
              </Link>
            </div> 
          </div>
        )
      })}
      </div>
      <div className="d-flex wrap_dots">
        <div className="owl-dots d-flex align-items-center mr-3">
          {data.map((item, i) => {
            return (
              <button key={i} aria-label="dots" type="button" 
                onClick={()=> setCurrentSlide(i)}
                className={`${i === currentSlide && 'active'} slider_dot`}>
              </button>)
          })}
        </div>
        <div className="owl_nav">
          <button aria-label="nav" 
            onClick={()=> setCurrentSlide(currentSlide <= 0 ? data.length-1 : currentSlide - 1)}
            className="owl-prev">
            {/* <IoIosArrowBack className="IoIosArrow"/> */}
          </button>
          <button aria-label="nav" 
            onClick={()=> setCurrentSlide(currentSlide >= data.length-1 ? 0 : currentSlide + 1)}
            className="owl-next">
            {/* <IoIosArrowForward className="IoIosArrow"/> */}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Slider;


// <div key={val} className="wrap_text"><p>{key}</p></div>
// <div className="wrap_text"><p>Scalable extended</p></div>
// <div className="wrap_text"><p>business office</p></div>
// <div className="wrap_text mb-5"><p>services</p></div>
// </div>
// <div className="owl-dots d-flex mt-3">
// {/* {data.map((item, i) => {
//   return (<button key={i} aria-label="dots" type="button" className="slider_dot"></button>)
// })} */}
// <button aria-label="dots" type="button" className="slider_dot active"></button>
// </div>
// )
// })}
